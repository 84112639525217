import Vue from 'vue'
import App from './App.vue'
import store from './store'
import request from './libs/request'
import router from '@/libs/router.js'
import '@/assets/public/index.css'
import '@/assets/public/common.css'
import '@/assets/public/style.css'
import '@/assets/public/include.css'
import '@/assets/public/reset.css'
import '@/assets/public/responsiveslides.css'


import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import VueLazyload from 'vue-lazyload'
 //图片懒加载
Vue.use(VueLazyload)


import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

//使用vue-meta
import VueMeta from 'vue-meta';

Vue.use(VueMeta);

Vue.use(ElementUI)

// 使用swiper插件
Vue.use(VueAwesomeSwiper)


//html转义方法
Vue.prototype.$deTag=function (Value) {
  let text = Value.replace(/<[^>]*>/g,'')
  text = text.replace(/&[^;]+;/g, '');
  return text
}

Vue.config.productionTip = false

Vue.prototype.$request = request

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    leftnav:{}
  },
  getters: {
    bannerImage: state => state.bannerImage
  },
  mutations: {
    setLeftnav(state, leftnav) {
      state.leftnav = leftnav;
    }
  },
  actions: {
    async getLeft({commit}) {
      const res = await axios.post('articlecategory/getPaginate', {
        fil: 3
      })
      if (res.data.code === 0) {
        const leftNav = res.data.data
        // 使用一个对象来存储中间结果
        const result = {};

        leftNav.forEach(item => {
          // 如果 parent_id 还不存在于 result 中，则初始化它
          if (!result[item.parent_id]) {
            result[item.parent_id] = {
              fa_show: item.fa_show,
              is_show: item.is_show,
              parentName: item.parentName,
              parent_id: item.parent_id,
              children: []
            };
          }

          // 将当前对象添加到对应 parent_id 的 children 数组中
          result[item.parent_id].children.push({
            childrenName: item.childrenName,
            children_id: item.children_id
          });
        });

        // 将中间结果对象转换为数组
        leftNav = Object.values(result);
      }
      commit('setLeftnav', leftnav)
    },
    
},
  modules: {
  }
})

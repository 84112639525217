<template lang="">
  <div class="foot">
    <div class="container">
      <div class="footdl">
        <dl>
          <dt><router-link to="/">首页</router-link></dt>
        </dl>
        <dl>
          <dt><router-link :to="{path:'/about',query:{id:1}}">协会概括</router-link></dt>
          <dd v-for="(item,index) in aboutList" :key="index">
            <router-link :to="{path:'/about',query:{id:item.id}}">
              <font>&gt;</font>{{item.title}}
            </router-link>
          </dd>
        </dl>
        <dl>
          <dt><router-link :to="{path:'/trends',query:{id:1}}">协会动态</router-link></dt>
          <dd v-for="(item,index) in newsList" :key="index">
            <router-link :to="{path:'/trends',query:{id:item.id}}">
              <font>&gt;</font>{{item.title}}
            </router-link>
          </dd>
        </dl>
        <dl v-for="(item,index) in topList" :key="index">
          <dt>
            <router-link v-if="item.parent_id!==28&&item.parent_id!==9"
              :to="{path:'/article',query:{id:item.children[0].children_id}}">{{item.parentName}}</router-link>
            <router-link v-if="item.parent_id===28" :to="{path:'/trends/new?id=3'}">{{item.parentName}}</router-link>
            <!-- <router-link v-if="item.parent_id===9" :to="{path:'/about/business?id=5'}">{{item.parentName}}</router-link> -->
          </dt>
          <dd v-for="(item,index) in item.children.slice(0,6)" :key="index">
            <router-link :to="{path:'/article',query:{id:item.children_id}}">
              <font v-if="item.childrenName!==null">&gt;</font>{{item.childrenName}}
            </router-link>
          </dd>
        </dl>
        <dl>
          <dt><router-link to="/trends/new?id=7">协会画册</router-link></dt>
        </dl>
        <dl>
          <dt><router-link to="/trendsDetail?id=43">协会全媒体</router-link></dt>
        </dl>
        <dl>
          <dt><router-link to="/about/business?id=5">联系我们</router-link></dt>
        </dl>
      </div>
      <div class="footlogo">
        <img class="img" :src="siteList[0].site_logo">
        <nav>
          <a href="javascript:;"><img :src="siteList[0].wechat_qr_code"><br>公众号</a>
          <a href="javascript:;"><img :src="siteList[0].service"><br>视频号</a>
          <a href="javascript:;"><img :src="siteList[0].applet"><br>小程序</a>
        </nav>
        <!-- <img :src="siteList[0].footer_right"> -->
      </div>
      <div class="footby">
        <p>地址：{{siteList[0].company_address}}　　电话：
          {{siteList[0].contact_phone}}　　管理员信箱：{{siteList[0].contact_email}}</p>
        <p>版权所有 ©fs.cfo.com.cn 佛山市总会计师协会 <a href="https://beian.miit.gov.cn/" target="_blank">备案号：{{siteList[0].icp_number}}</a> <a
            href="https://www.cyxn.com/" target="_blank">技术支持：友道科技</a></p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        aboutList: [],
        topList: [],
        siteList:[],
        newsList: [],
        activeIndex: -1,
      }
    },
      metaInfo() {
      return {
        meta: [
          {
            name: 'keywords',
            content:  this.siteList.length > 0 ? this.siteList[0].seo_keywords : '',
          },
          {
            name: 'description',
            content: this.siteList.length > 0 ? this.siteList[0].seo_description : '',
          },
        ],
      }
    },
    watch: {
    siteList: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.$nextTick(() => {
            this.updateMetaInfo();
          });
        }
      },
      immediate: true, 
    },
  },
    mounted() {
      this.getTop()
      this.getAbout()
      this.getNews()
      this.getSite()
    },
    methods: {
       updateMetaInfo() {
      this.$metaInfo.meta = [
        {
          name: 'keywords',
          content: this.siteList[0].seo_keywords,
        },
        {
          name: 'description',
          content: this.siteList[0].seo_description,
        },
      ];
    },
      async getSite(){
        const res = await this.$request.post('settingsite/getPaginate')
        if (res.data.code===0) {
          this.siteList=res.data.data.data
        }
      },
      async getTop() {
        const res = await this.$request.post("articlecategory/getPaginate", {
          fil: 3
        });
        if (res.data.code === 0) {
          this.topList = res.data.data;

          // 使用一个对象来存储中间结果
          const result = {};

          this.topList.forEach(item => {
            // 如果 parent_id 还不存在于 result 中，则初始化它
            if (!result[item.parent_id]) {
              result[item.parent_id] = {
                fa_show: item.fa_show,
                is_show: item.is_show,
                parentName: item.parentName,
                parent_id: item.parent_id,
                sort: item.parent_sort
              };
            }
            // 将当前对象添加到对应 parent_id 的 children 数组中
            result[item.parent_id].children =
            result[item.parent_id].children || [];
            result[item.parent_id].children.push({
              childrenName: item.childrenName,
              children_id: item.children_id
            });
          });

          // 将中间结果对象转换为数组
          this.topList = Object.values(result);
          this.topList = this.sortArr(this.topList)
        }
      },
      async getAbout() {
        const res = await this.$request.get("about/getPaginate");
        if (res.data.code === 0) {
          this.aboutList = res.data.data.data;
        }
      },
      async getNews() {
        const res = await this.$request.post("newscategory/getPaginate", {
          fil: 1
        });
        if (res.data.code === 0) {
          this.newsList = res.data.data;
        }
      },
      // 排序方法
      sortArr(array) {
        return array.sort((a, b) => {
          // 如果 a.sort 或 b.sort 是 undefined，将其视为最小值
          const sortA = a.sort === undefined ? -Infinity : a.sort;
          const sortB = b.sort === undefined ? -Infinity : b.sort;
          return sortA - sortB;
        });
      }
      ,
    },
  }
</script>
<style lang="scss" scoped>
  .foot {
    padding-top: 50px;
    background: #3e3f43;
  }

  p{
    color: #fff;
  }

  a{
    color: #fff;
  }

  .foot .container {
    height: 600px;
  }
  .foot .footlogo{
    justify-content: center;
  }
  .foot .footdl dl:nth-child(6){
    margin: 0;
  }
  .img{
    height: 90px;
  }
  .foot .footlogo nav a img{
    width: 150px;
    height: 150px;
  }
  @media screen and (max-width:768px) {
    .img{
      object-fit: contain;
    }
    .foot .footlogo{
      display: flex;
      flex-direction: column;
    }
    .foot .footlogo nav{
      border: none;
      display: flex;
      margin: none;
      justify-content: space-between;
    }
    .foot .footlogo nav a{
      width: 30%;
      float: left;
      margin-right: 5vw;
      text-align: center;
      font-size: 14px
    }
    .foot .footlogo nav a img{
      width: 100%;
      height: 10vh;
      object-fit: contain;
    }
    .foot .footdl {
      display: flex;
      flex-wrap: wrap;
    }
    .foot .footlogo{
      margin: 0;
    }
    .foot .footdl dl:first-child{
    display: none;
    }
    .foot .footdl dl{
    float: left;
    margin-right: 10vw;
    margin-bottom: 3vh;
    }
    .footby{
     display: none;
    }
    .foot .container{
      height: 100%;
    }
    
  }
</style>